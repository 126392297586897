.step-animation{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.step-animation .container {
    position: relative;
    width: 400px;
    height: 350px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.step-animation .icon-container {
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-icon {
    font-size: 5rem;
    color: #6200ea;
    opacity: 0;
    animation: slideInRight 1s ease-in-out forwards;
}

.complete-icon {
    font-size: 5rem;
    color: #28a745;
    animation: fadeIn 1s ease-in-out forwards;
}

.step-text {
    font-size: 18px;
    color: #333;
    margin-top: 20px;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
    margin-top: 20px;
}

.progress {
    height: 100%;
    background-color: #8dd13e;
    width: 0%;
    border-radius: 5px;
    transition: width 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.progress::after {
    content: "";
    position: absolute;
    top: 0;
    left: -30%; /* Старт за пределами */
    width: 30%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
    animation: shine 1.5s infinite linear;
}

@keyframes shine {
    0% {
        left: -30%;
    }
    100% {
        left: 100%;
    }
}
