.clinic-form-container{
    background: #ffffff;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.05);
    min-height: 70vh;
    max-width: 800px;
    margin: 0 auto;
    border: 1px solid #e0e0e0;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
}

.clinic-form-container .ant-spin-container,.clinic-form-container .ant-spin-nested-loading{
    display:flex;
    flex:1;
    flex-direction: column;
}
